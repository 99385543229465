import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Statistic, Empty } from 'antd';
import axios from 'axios';
import SEO from '../blocks/layout/seo';
import AppContainer from '../blocks/layout';

import { Firebase } from '../config/firebase';
import { formatUsageData } from '../config/custom/functions';
import UsageGraph from '../blocks/codes/usageGraph';

import '../styles/usage.scss';

// markup
const Usage = () => {
      const [loading, setLoading] = useState(false);
      const [usageData, setUsageData] = useState(null);
      const [totalStats, setTotalStats] = useState(null);

      useEffect(() => {
            async function fetchAPIUsage(user) {
                  setLoading(true);
                  const result = await axios.get(
                        `${process.env.GATSBY_BASE_URL}/api_limit_counts/${user.uid}`
                  );
                  const callsInfo = result.data;

                  if (
                        callsInfo.countObject !== 'no call' &&
                        callsInfo.countObject !== undefined &&
                        callsInfo.countObject !== undefined
                  ) {
                        if (callsInfo.count > 1) {
                              setTotalStats(callsInfo);
                              setUsageData(
                                    formatUsageData(callsInfo.countObject)
                              );
                              setLoading(false);
                        }
                  } else {
                        setLoading(false);
                  }
            }
            Firebase.auth().onAuthStateChanged((user) => {
                  if (user) {
                        fetchAPIUsage(user);
                  }
            });
      }, []);

      return (
            <>
                  <SEO title="Usage — NoCodeAPI" />
                  <AppContainer activeNav="usage">
                        <div className="usage-container">
                              {totalStats !== null ? (
                                    <>
                                          <div className="usage-numbers">
                                                <div className="statistic-card">
                                                      <Statistic
                                                            title="Total Requests"
                                                            value={
                                                                  totalStats.reqLimits
                                                            }
                                                      />
                                                </div>

                                                <div className="statistic-card">
                                                      <Statistic
                                                            title="Consumed"
                                                            value={`${(
                                                                  parseInt(
                                                                        parseInt(
                                                                              totalStats.count
                                                                        ) * 100
                                                                  ) /
                                                                  parseInt(
                                                                        totalStats.reqLimits
                                                                  )
                                                            ).toFixed(0)}%`}
                                                      />
                                                </div>

                                                <div className="statistic-card">
                                                      <Statistic
                                                            title="Limit will reset"
                                                            value={`${moment
                                                                  .unix(
                                                                        totalStats.startTime
                                                                  )
                                                                  .add(1, 'M')
                                                                  .fromNow()}`}
                                                      />
                                                </div>
                                          </div>

                                          <UsageGraph usageData={usageData} />
                                          <p>Notes:</p>
                                          <ul className="usage-notes">
                                                <li>
                                                      For free users: If you
                                                      consumed 100% api calls
                                                      within a month then you
                                                      will get 429 error.
                                                </li>{' '}
                                                <li>
                                                      For paid users: If you
                                                      consumed 100% api calls
                                                      within a month then your
                                                      api will work without any
                                                      issues but you have to
                                                      upgrade your subscription
                                                      ASAP.
                                                </li>
                                                <li>
                                                      Limit reset every month
                                                      the day you subscribe.{' '}
                                                      {totalStats !== null &&
                                                            `FYI: Your limit will reset ${moment
                                                                  .unix(
                                                                        totalStats.startTime
                                                                  )
                                                                  .add(1, 'M')
                                                                  .fromNow()}`}
                                                </li>
                                          </ul>
                                    </>
                              ) : (
                                    <>
                                          <Empty
                                                image={
                                                      Empty.PRESENTED_IMAGE_SIMPLE
                                                }
                                                description="There is no usage. Go to marketplace and start using API, then you can see the usage graph."
                                          />
                                    </>
                              )}
                        </div>
                  </AppContainer>
            </>
      );
};

export default Usage;
