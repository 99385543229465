import React from 'react';
import moment from 'moment';
import {
      LineChart,
      Legend,
      XAxis,
      YAxis,
      CartesianGrid,
      Tooltip,
      ResponsiveContainer,
      Line,
} from 'recharts';

const CustomizedLabel = ({ x, y, stroke, value }) => (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
            {value}
      </text>
);

const UsageGraph = ({ usageData }) => {
      const renderCustomAxisTick = (tickItem) =>
            moment(tickItem).format('Do MMM');

      const renderCustomBarLabel = (datetime) =>
            moment(datetime).format('dddd, MMM Do');

      return (
            <>
                  {usageData !== null && usageData.length > 0 && (
                        <div
                              style={{
                                    width: '102%',
                                    height: 300,
                                    marginTop: 60,
                              }}
                        >
                              <ResponsiveContainer>
                                    <LineChart
                                          data={usageData}
                                          width={900}
                                          height={300}
                                          margin={{
                                                top: 10,
                                                right: 30,
                                                left: 0,
                                                bottom: 0,
                                          }}
                                    >
                                          <XAxis
                                                dataKey="date"
                                                tickFormatter={
                                                      renderCustomAxisTick
                                                }
                                                tick={{
                                                      fontSize: 11,
                                                      fill: '#000000',
                                                      fontWeight: 300,
                                                }}
                                          />
                                          <CartesianGrid strokeDasharray="3 3" />

                                          <Tooltip
                                                wrapperStyle={{
                                                      fontSize: '12px',
                                                }}
                                                labelFormatter={(index) =>
                                                      renderCustomBarLabel(
                                                            index
                                                      )
                                                }
                                                formatter={(value) =>
                                                      `${value} requests`
                                                }
                                          />
                                          <Line
                                                type="monotone"
                                                dataKey="requests"
                                                stroke="#000000"
                                                label={<CustomizedLabel />}
                                          />
                                    </LineChart>
                              </ResponsiveContainer>
                        </div>
                  )}
            </>
      );
};

export default UsageGraph;
